import {FC} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  selectedTab: string
  setSelectedTab: (tab: string) => void
}

export const SettingsSideMenu: FC<Props> = ({selectedTab, setSelectedTab}) => {
  const navigate = useNavigate()

  const navigationTabs = [
    {name: 'Sectors', path: '/settings'},
    {name: 'Financing Types', path: '/settings'},
    {name: 'Users', path: '/settings'},
    {name: 'Roles', path: '/settings'},
  ]

  const handleTabClick = (tab: string, path: string) => {
    setSelectedTab(tab)
    navigate(path)
  }

  return (
    <div className="card card-flush card-bordered card-60vh">
      {navigationTabs.map((tab, index) => (
        <div
          key={index}
          className={`settings-item ${selectedTab === tab.name ? 'selected-setting' : ''} d-flex`}
          onClick={() => handleTabClick(tab.name, tab.path)}
        >
          <span className="flex-start">{tab.name}</span>
        </div>
      ))}
    </div>
  )
}
