/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from 'react-oidc-context'
import {useIsGlobalAdmin, useIsSuperUser} from '../../../utils/authUtil'

export function AsideMenuMain() {

  const isGlobalAdmin = useIsGlobalAdmin()
  const isSuperUser = useIsSuperUser()
  return (
    <>
      {/*<AsideMenuItem*/}
      {/*    to='/overview'*/}
      {/*    icon='/media/icons/duotune/general/gen025.svg'*/}
      {/*    title='Overview'*/}
      {/*    fontIcon='bi-app-indicator'*/}
      {/*/>*/}

      <AsideMenuItem
        to="/companies?page=1&pageSize=6"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Companies"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/financiers"
        icon="/media/icons/duotune/communication/com005.svg"
        title="Financiers"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/development/partners"
        icon="/media/icons/duotune/general/gen025.svg"
        title="Development Partners"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/services/providers"
        icon="/media/icons/duotune/finance/fin006.svg"
        title="Service Providers"
        fontIcon="bi-app-indicator"
      />
      {(isGlobalAdmin || isSuperUser) && (
        <AsideMenuItem
          to="/settings"
          icon="/media/icons/duotune/technology/teh004.svg"
          title="Settings"
          fontIcon="bi-app-indicator"
        />
      )}

    </>
  )
}
